import { EventType, InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import { getCurrentPage } from "./index";

const STANDARD_SCOPES = [
    "openid",
    "profile",
    "https://lsonsightplus.onmicrosoft.com/mtrest/generic_oauth2_access",
];

export class UserAccount {
    id = "";                // "sub"
    name = "";              // "name"
    email = "";             // "email"
    roles = [];             // "roles"
    activeTenant = "";      // "appTenantName"
    tenants = [];           // "allTenants"

    isAuthenticated() {
        return Boolean(this.id || this.name);
    }
};

export class CustomPageLowAuthService {
    #_eventHandlers = [];

    getUserAccount() {
        let user = new UserAccount();
        const page = getCurrentPage();
        const pathParts = window.location.pathname.split("/");
        const lastPart =
            pathParts.length === 0 ? "" : pathParts[pathParts.length - 1];
        const questionMark = lastPart.indexOf("?");
        const searchParams = new URLSearchParams(window.location.search);

        user.id = page?.id;
        user.name = lastPart.substring(
            0,
            questionMark > 0 ? questionMark : lastPart.length
        );
        user.email = searchParams.get("email") || "";
        user.activeTenant = process.env.REACT_APP_APPTENANT_NAME;
        user.tenants = [process.env.REACT_APP_APPTENANT_NAME];
        user.roles = [`${process.env.REACT_APP_B2C_CLIENT_ID}::User`];

        return user;
    }

    async getAuthHeaders(scopes) {
        return {
            "X-OnsightPlus-TenantName": "CRAIGTENANT",
            "X-OnsightPlus-TenantId": "6d31bd08-dbf5-4597-a308-a5a92e94eaea",
            "X-OnsightPlus-WebAppId": process.env.REACT_APP_B2C_CLIENT_ID,
            "X-OnsightPlus-WebAppPath": window.location.pathname,
        };
    }

    async login() {
//        console.log("Calling authService.login()");
    }

    addAuthorizationHandler(handler) {
        this.#_eventHandlers.push(handler);

        // If user has already authentcated, run a pre-emptive authorization immediately
        const userAccount = this.getUserAccount();
        if (userAccount.isAuthenticated()) {
            // User already logged in; apply the given handler immediately
            handler(userAccount);
        }
    }
}

export class MsalAuthService {
    #_msal;
    #_eventHandlers = [];
    #_lastMsalEvent;
    config = {
        signInAuthority: "",
        signInScopes: [],
    };

    constructor(msal, config) {
        this.#_msal = msal;
        this.config = config;

        this.#_msal.addEventCallback((event) => {
            if (
                (event.eventType === EventType.LOGIN_SUCCESS ||
                    event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
                    event.eventType === EventType.SSO_SILENT_SUCCESS) &&
                event.payload.account
            ) {
                // Reset "tenant" extra query param once auth request received;
                // this param is only needed when changing tenants (or when first opening the app).
                loginRequest.extraQueryParameters = {};

                // Login succeeded; attempt authorization
                this.#_msal.setActiveAccount(event.payload.account);
                if (this.#shouldCallAuthorize(event)) {
                    // Save the last-known app tenant for continuation
                    localStorage.setItem(
                        "lastAppTenant",
                        event.payload.account.idTokenClaims.appTenantName
                    );
                    // Invoke each handler
                    const userAccount = this.getUserAccount();
                    this.#_eventHandlers.forEach((handler) =>
                        handler(userAccount)
                    );
                }
                this.#_lastMsalEvent = event;
            } else if (
                event.eventType === EventType.LOGIN_FAILURE ||
                event.eventType === EventType.SSO_SILENT_FAILURE
            ) {
                // Login failure
                const blankUser = new UserAccount();
                this.#_eventHandlers.forEach((handler) => handler(blankUser));
            }
        });
    }

    getUserAccount() {
        const msalAccount = this.#_msal.getActiveAccount();
        let user = new UserAccount();
        if (msalAccount) {
            user.id = msalAccount.idTokenClaims.sub;
            user.name = msalAccount.idTokenClaims.name;
            user.email = msalAccount.idTokenClaims.email;
            user.activeTenant = msalAccount.idTokenClaims.appTenantName;
            user.tenants = msalAccount.idTokenClaims.allTenants;
            user.roles = msalAccount.idTokenClaims.roles;
        }

        return user;
    }

    async getAuthHeaders(scopes) {
        const accessToken = await getToken(scopes || STANDARD_SCOPES);
        return {
            Authorization: `Bearer ${accessToken}`
        };
    }

    async getToken(scopes) {
        const account = authService.getUserAccount();
        let accessToken = "";

        if (!account.isAuthenticated()) {
            throw Error(
                "No active account! Verify a user has been signed in and setActiveAccount has been called."
            );
        }

        try {
            // First try using cached or silently-refreshed token
            accessToken = await authService.acquireTokenSilent(scopes);
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                // Silent token refresh not allowed; show popup to force user re-auth
                accessToken = await authService.acquireTokenPopup(scopes);
            }
        }

        return accessToken;
    }

    /**
     *
     * @param {[string]} scopes
     * @returns
     */
    async acquireTokenSilent(scopes) {
        const tokenRequest = {
            scopes,
            account: this.#_msal.getActiveAccount(),
        };

        const response = await this.#_msal.acquireTokenSilent(tokenRequest);
        return response.accessToken;
    }

    /**
     *
     * @param {[string]} scopes
     * @returns
     */
    async acquireTokenPopup(scopes) {
        const tokenRequest = {
            scopes,
            account: this.#_msal.getActiveAccount(),
        };

        const response = await this.#_msal.acquireTokenPopup(tokenRequest);
        return response.accessToken;
    }

    logoutRedirect(logoutRequest) {
        return this.#_msal.logoutRedirect(logoutRequest);
    }

    async switchTenant(newTenant) {
        return this.#_msal.loginRedirect({
            authority: this.config.signInAuthority,
            scopes: this.config.signInScopes,
            account: this.#_msal.getActiveAccount(),
            extraQueryParameters: { tenant: newTenant },
        });
    }

    addAuthorizationHandler(handler) {
        this.#_eventHandlers.push(handler);

        // If user has already authentcated, run a pre-emptive authorization immediately
        const userAccount = this.getUserAccount();
        if (userAccount.isAuthenticated()) {
            // User already logged in; apply the given handler immediately
            handler(userAccount);
        }
    }

    /**
     * Determines if authorization event handlers should be invoked based on the given MSAL event.
     * @param {EventType} msalEvent
     * @returns
     */
    #shouldCallAuthorize(msalEvent) {
        // Most anything will trigger a reauthorization call, EXCEPT
        // a silent token acquisition beyond the first event.
        // (ie, avoid calling authorization when user is just clicking around).
        return (
            msalEvent.eventType != EventType.ACQUIRE_TOKEN_SUCCESS ||
            msalEvent.interactionType == InteractionType.Redirect ||
            msalEvent.interactionType == InteractionType.Popup ||
            !this.#_lastMsalEvent
        );
    }
};