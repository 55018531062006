import { matchRoutes, useLocation } from "react-router-dom";

const useCurrentPage = (availablePages) => {
    const location = useLocation();
    const matches = matchRoutes(availablePages, location);

    return matches?.length > 0 ? matches[0].route : {};
};

export default useCurrentPage;
