import React, { useState, useEffect, useRef } from "react";
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { RestrictedPage } from "./pages/RestrictedPage";
import { LoadingPage } from "./pages/LoadingPage";
import { UserWebApps } from "./onsight-plus-ext";
import { Container } from "./components/dashboard/Container";
import { NotFound } from "./components/NotFound";
import { RequestForm } from "./components/RequestForm"; 
import { setCurrentPage } from "./index";
import useCurrentPage from "./utils/useCurrentPage";
import CallCenterLobby from "./components/CallCenterLobby";

import "./styles/index.css";
import "./styles/App.css";

import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import CustomPageNoAuth from "./components/CustomPageNoAuth";

const App = ({ authService }) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [appPages, setAppPages] = useState([]);
  const [pageId, setPageId] = useState("");
  const [searchParams, _] = useSearchParams();
  const [userRoutes, setUserRoutes] = useState([]);

  const [isAdmin, setIsAdmin] = useState(false);
  const isAdminRef = useRef(false);
  isAdminRef.current = isAdmin;

  const [isProd, setIsProd] = useState(true);
  const isProdRef = useRef(true);
  isProdRef.current = isProd;

  const page = useCurrentPage(appPages);
  useEffect(() => {
    setCurrentPage(page);
    //onAuthorize(authService.getUserAccount());
  }, [page]);

  const onAuthorize = async (userAccount) => {
    setCurrentUser(userAccount);
    setPageId(searchParams.get("pageId"));

    try {
      userAccount.roles.forEach((r) => {
        if (r.includes("Administrator")) {
          isAdminRef.current = true;
          setIsAdmin(true);
        }
      });

      // still do not display hamburger menu even if Admin if not using admin path
      if (isAdminRef.current) {
        const isAdminPath = window.location.pathname;
        if (isAdminPath !== "/admin") {
          isAdminRef.current = false;
          setIsAdmin(false);
        }

        const url = window.location;
        if (url.hostname === "localhost") {
          isProdRef.current = false;
          setIsProd(false);
        }
      }

      //const allConfigs = await UserWebApps.getAllConfigs();
      const userPages = await UserWebApps.getUserPages();
      setAppPages(userPages);

      const userRoutes = userPages.map((page) => 
        
          <Route {...page} 
            key={page.id} 
            element={
              <CustomPageNoAuth>
                <RequestForm />
              </CustomPageNoAuth>}>
          </Route>
        
          // element={<Container
          //   pageId={page.id}
          //   userAccount={currentUser}
          //   isAdmin={false}
          //   isProd={isProdRef.current} /> }
      );
      const lobbyRoutes = userPages.map((page) => page.index
        ? <></>
        : <Route path={`${page.path}/lobby`} key={`${page.id}/lobby`} element={<CallCenterLobby />} />);

      userRoutes.push(lobbyRoutes);
      // Add some standard routes in addition to user-specific ones
      userRoutes.push(<Route key="_404" path="*" element={<NotFound status={404} />} />);
      userRoutes.push(<Route key="_admin" path="/admin" 
        element={isAdminRef.current && 
          <Container
            pageId={pageId}
            userAccount={currentUser}
            isAdmin={true}
            isProd={isProdRef.current} 
          />}
      />);

      setUserRoutes(userRoutes);     
      setIsAuthorized(true);
   } catch (error) {
      setNotAuthorized(true);
    }
  };

  useEffect(() => {
    authService.addAuthorizationHandler(onAuthorize);
  }, []);

  return (
    <>
      {isAuthorized && (
        <Routes>
          {userRoutes}
        </Routes>
      )}
      {notAuthorized && <RestrictedPage />}
      {!isAuthorized && !notAuthorized && <LoadingPage />}
    </>
  );
};
export default App;
