import React, { useState } from "react";
import { Button, Form } from 'react-bootstrap';
import Select from "react-select";
import { CallCenter } from "../onsight-plus-ext";

const CallbackForm = (props) => {
    const priorityOptions = [
        { label: "Urgent", value: "Urgent" },
        { label: "Not Urgent", value: "NotUrgent" },
    ];
    
    const [selectedPriority, setSelectedPriority] = useState(priorityOptions[0]);
    const [onsightEmailAddress, setOnsightEmailAddress] = useState("");
    const [comments, setComments] = useState("");
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = async () => {
        const response = await CallCenter.updateRequest("my-call-center", props.requestId, {
            id: props.requestId,
            status: "RequiresCallback",
            callback: {
                priority: selectedPriority.value,
                onsightEmailAddress,
                comments
            }
        });

        setSubmitted(true);
    };

    return (
        <div style={{display: props.hidden ? "none" : "inherit"}}>
            {!submitted && <div className="card" style={{ margin: "20px" }}>
                <div className="card-header font-weight-bold">Request Callback</div>
                <div style={{ height: "100%", width: "100%", padding: "30px" }} className="">
                    <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
                        <div style={{ flexGrow: "1" }}>
                            <div style={{ height: "100%", width: "100%" }}>
                                <Select
                                    style={{width: "25%"}}
                                    value={selectedPriority}
                                    onChange={newValue => setSelectedPriority(newValue)}
                                    options={priorityOptions} />
                                <p />
                                <Form.Control
                                    className="form-control me-2"
                                    value={onsightEmailAddress}
                                    onChange={evt => setOnsightEmailAddress(evt.target.value)}
                                    type="email"
                                    placeholder="Onsight Email Address"
                                    aria-label="Onsight Email Address" />
                                <p />
                                <textarea
                                    className="form-control me-2"
                                    rows="3"
                                    value={comments}
                                    onChange={evt => setComments(evt.target.value)}
                                    type="textarea"
                                    placeholder="Comment..."
                                    aria-label="Comment"
                                />
                                <p />
                                <br />
                                <Button onClick={handleSubmit} className="btn btn-primary ml-auto col-auto" type="submit">
                                    Request Callback
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {submitted && <h4>Your callback request has been submitted. You may close this window.</h4>}
        </div>
    );
};

export default CallbackForm;