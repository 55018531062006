import React, { useState, useEffect, useRef } from "react";
import { Card, Form, Button, Row, Col } from "react-bootstrap";
import { MappingInput } from "./MappingInput";

export const ChildContent = (props) => {
  const [id, setId] = useState(0);
  const setIdRef = useRef(0);
  setIdRef.current = id;
  const [blockNameValue, setBlockNameValue] = useState("");
  const [dataSourceValue, setDataSourceValue] = useState("");
  const [initialLoad, setInitialLoad] = useState(1);

  if (props !== undefined && props.mappingInput !== undefined && props.mappingInput.length !== 0) {
    props.mappingInput.find((mi) => mi.no === props.blockId).type = props.selected;
  }

  const addMappingInput = (type, selected) => {
    let highestNoInput = 0;
    if (props.mappingInput.length !== 0) {
      const listOfMappingInputNo = props.mappingInput[0].inputs.map((i) => Number(i.props.id.replace("_mappingInput", "")));
      if (listOfMappingInputNo.length !== 0) {
        highestNoInput = Math.max(...listOfMappingInputNo);
      }
    }

    const newId = Number(highestNoInput) + 1;
    const newMappingInputId = newId + "_mappingInput";
    const keyId = String.fromCharCode(newId + 64).toLowerCase();

    setIdRef.current = newId;
    setId(newId);

    if (props.mappingInput.length !== 0) {
      props.mappingInput
        .find((mi) => mi.no === props.blockId)
        .inputs.push(
          <MappingInput
            setTextInputOptionsHandler={props.setTextInputOptionsHandler}
            textInputValues={props.textInputValues}
            type={type}
            key={keyId}
            id={newMappingInputId}
            removeMappingInput={props.removeMappingInput}
            blockId={props.blockId}
            no={newId}
          />
        );
      props.setMappingInput(props.mappingInput);
    } else {
      const mi = (
        <MappingInput type={type} key={keyId} id={newMappingInputId} removeMappingInput={props.removeMappingInput} blockId={props.blockId} no={newId} />
      );
      const newMappingInput = { inputs: [mi], no: props.blockId, parent_id: props.blockId + "_ParentDDLcard", type: String(selected) };
      props.setMappingInput([newMappingInput], true);
    }
  };

  useEffect(() => {
    if (initialLoad === 1) {
      if (props.initialValuesFromAPI !== undefined) {
        let valuesForBlock = props.initialValuesFromAPI.find((iv) => iv.id === String(props.blockId));
        if (valuesForBlock !== undefined) {
          if (valuesForBlock.blockType === "table" || valuesForBlock.blockType === "informational") {
            setBlockNameValue(valuesForBlock.blockName);
            setDataSourceValue(valuesForBlock.dataSource);
          } else if (valuesForBlock.blockType === "search") {
            setDataSourceValue(valuesForBlock.dataSource);
          }

          let numArr = [];
          props.initialValuesFromAPI.forEach((ibc) => {
            numArr.push(ibc.id);
          });
          let newBlockId = Math.max(...numArr) + 1;

          setId(newBlockId);
          setInitialLoad(0);
        }
      }
    }
  }, [initialLoad, props.blockId, props.initialValuesFromAPI, props.initialBlockConfig]);

  return props.selected === "1" ? (
    <Card className="my-1 childContent blockTable" id={props.blockId} key={props.blockKeyId}>
      <Form className="p-3">
        <Form.Group className={`${props.isProd ? "mb-3 d-none" : "mb-3"}`}>
          <Form.Label>Identifiers</Form.Label>
          <Form.Control className="mb-1 blockId" readOnly type="datasource" value={props.blockId} placeholder="Enter Block ID" />
          <Form.Control className="mb-1 blockKey" readOnly type="datasource" value={props.blockKeyId} placeholder="Enter Block Key" />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Data Source</Form.Label>
          <Form.Control className="mb-1 dataSource" type="datasource" defaultValue={dataSourceValue} placeholder="Enter Data Source" />
          <Form.Control type="titlename" className="blockName" defaultValue={blockNameValue} placeholder="Enter Name" />
        </Form.Group>

        <Form.Group className="mb-3 mappingInput">
          <Form.Label>Mapping</Form.Label>
          <Row className="px-0 py-1 pl-1">
            <Col sm="auto">
              <Button type="button" onClick={() => addMappingInput("blockTable", props.selected)} className="btn btn-secondary">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg>
              </Button>
            </Col>
          </Row>
          {props.mappingInput.length !== 0 ? props.mappingInput.find((mi) => mi.no === props.blockId).inputs.map((input) => input) : []}
        </Form.Group>
      </Form>
    </Card>
  ) : props.selected === "2" ? (
    <Card className="my-1 childContent blockInformational" id={props.blockId} key={props.blockKeyId}>
      <Form className="p-3">
        <Form.Group className={`${props.isProd ? "mb-3 d-none" : "mb-3"}`}>
          <Form.Label>Identifiers</Form.Label>
          <Form.Control className="mb-1 blockId" readOnly type="datasource" value={props.blockId} placeholder="Enter Block ID" />
          <Form.Control className="mb-1 blockKey" readOnly type="datasource" value={props.blockKeyId} placeholder="Enter Block Key" />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Data Source</Form.Label>
          <Form.Control className="mb-1 dataSource" type="datasource" defaultValue={dataSourceValue} placeholder="Enter Data Source" />
          <Form.Control type="titlename" className="blockName" defaultValue={blockNameValue} placeholder="Enter Name" />
        </Form.Group>

        <Form.Group className="mb-3 mappingInput">
          <Form.Label>Mapping</Form.Label>
          <Row className="px-0 py-1 pl-1">
            <Col sm="auto">
              <Button type="button" onClick={() => addMappingInput("blockInformational", props.selected)} className="btn btn-secondary">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg>
              </Button>
            </Col>
          </Row>
          {props.mappingInput.length !== 0 ? props.mappingInput.find((mi) => mi.no === props.blockId).inputs.map((input) => input) : []}
        </Form.Group>
      </Form>
    </Card>
  ) : props.selected === "3" ? (
    <Card className="my-1 childContent blockButtons" id={props.blockId} key={props.blockKeyId}>
      <Form className="p-3">
        <Form.Group className={`${props.isProd ? "mb-3 d-none" : "mb-3"}`}>
          <Form.Label>Identifiers</Form.Label>
          <Form.Control className="mb-1 blockId" readOnly type="datasource" value={props.blockId} placeholder="Enter Block ID" />
          <Form.Control className="mb-1 blockKey" readOnly type="datasource" value={props.blockKeyId} placeholder="Enter Block Key" />
        </Form.Group>

        <Form.Group className="mb-3 mappingInput">
          <Form.Label>Mapping</Form.Label>
          <Row className="px-0 py-1 pl-1">
            <Col sm="auto">
              <Button type="button" onClick={() => addMappingInput("blockButtons", props.selected)} className="btn btn-secondary">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg>
              </Button>
            </Col>
          </Row>
          {props.mappingInput.length !== 0 ? props.mappingInput.find((mi) => mi.no === props.blockId).inputs.map((input) => input) : []}
        </Form.Group>
      </Form>
    </Card>
  ) : props.selected === "4" ? (
    <Card className="my-1 childContent blockFreeForm" id={props.blockId} key={props.blockKeyId}>
      <Form className="p-3">
        <Form.Group className={`${props.isProd ? "mb-3 d-none" : "mb-3"}`}>
          <Form.Label>Identifiers</Form.Label>
          <Form.Control className="mb-1 blockId" readOnly type="datasource" value={props.blockId} placeholder="Enter Block ID" />
          <Form.Control className="mb-1 blockKey" readOnly type="datasource" value={props.blockKeyId} placeholder="Enter Block Key" />
        </Form.Group>

        <Form.Group className="mb-3 mappingInput">
          <Form.Label>Mapping</Form.Label>
          <Row className="px-0 py-1 pl-1">
            <Col sm="auto">
              <Button type="button" onClick={() => addMappingInput("blockFreeForm", props.selected)} className="btn btn-secondary">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg>
              </Button>
            </Col>
          </Row>
          {props.mappingInput.length !== 0 ? props.mappingInput.find((mi) => mi.no === props.blockId).inputs.map((input) => input) : []}
        </Form.Group>
      </Form>
    </Card>
  ) : props.selected === "5" ? (
    <Card className="my-1 childContent blockSearch" id={props.blockId} key={props.blockKeyId}>
      <Form className="p-3">
        <Form.Group className={`${props.isProd ? "mb-3 d-none" : "mb-3"}`}>
          <Form.Label>Identifiers</Form.Label>
          <Form.Control className="mb-1 blockId" readOnly type="datasource" value={props.blockId} placeholder="Enter Block ID" />
          <Form.Control className="mb-1 blockKey" readOnly type="datasource" value={props.blockKeyId} placeholder="Enter Block Key" />
        </Form.Group>

        <Form.Group className="mb-3 mappingInput">
          <Form.Label>Mapping</Form.Label>
          <Row className="px-0 py-1 pl-1">
            <Col sm="auto">
              <Button type="button" onClick={() => addMappingInput("blockSearch", props.selected)} className="btn btn-secondary">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg>
              </Button>
            </Col>
          </Row>
          {props.mappingInput.length !== 0 ? props.mappingInput.find((mi) => mi.no === props.blockId).inputs.map((input) => input) : []}
        </Form.Group>
      </Form>
    </Card>
  ) : props.selected === "6" ? (
    <Card className="my-1 childContent blockSpacer" id={props.blockId} key={props.blockKeyId}>
      <Form className={`${props.isProd ? "p-3 d-none" : "p-3"}`}>
        <Form.Group className="mb-3">
          <Form.Label>Identifiers</Form.Label>
          <Form.Control className="mb-1 blockId" readOnly type="datasource" value={props.blockId} placeholder="Enter Block ID" />
          <Form.Control className="mb-1 blockKey" readOnly type="datasource" value={props.blockKeyId} placeholder="Enter Block Key" />
        </Form.Group>
      </Form>
    </Card>
  ) : (
    <Card className="mt-1" id={props.blockId} key={props.blockKeyId} />
  );
};
