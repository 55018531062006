import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSearchParams } from "react-router-dom";
import { HubConnectionBuilder } from '@microsoft/signalr';
import { CallCenter } from '../onsight-plus-ext';
import { authService } from '..';
import CallbackForm from './CallbackForm';

const CallCenterLobby = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const ccRequestId = searchParams.get("ccReqId");
    const [requestCallback, setRequestCallback] = useState(false);
    const [canceled, setCanceled] = useState(false);

    const handleCancelClick = async () => {
        const response = await CallCenter.updateRequest("my-call-center", ccRequestId, {
            id: ccRequestId,
            status: "Canceled"
        });
        setCanceled(true);
    };

    useEffect(() => {
        document.title = "Call Center Lobby";
        if (!ccRequestId) {
            return;
        }

        const websocketUrl = `${CallCenter.requesterHubUrl}?tenant=${authService.getUserAccount().activeTenant}&ccReqId=${ccRequestId}`;
        const connection = new HubConnectionBuilder()
            .withUrl(websocketUrl)
            .withAutomaticReconnect()
            .build();

        connection.start()
            .then(result => {
                connection.on('BeginOnsightSession', ocUrl => {
                    console.log(ocUrl);
                    window.location.href = ocUrl;
                });
            })
            .catch(e => console.log('Connection failed: ', e));
    }, []);
    
    return (
        <div style={{margin: "20px"}}>
            <h3>Request submitted. Please wait for the meeting to start...</h3>
            <br />
            <Button hidden={requestCallback || canceled} onClick={handleCancelClick}>Cancel Request</Button>
            &nbsp;&nbsp;&nbsp;
            <Button hidden={requestCallback || canceled} onClick={() => setRequestCallback(true)}>Request Callback</Button>
            <h4 hidden={!canceled}>Support request has been canceled. You may close this window.</h4>
            <CallbackForm hidden={!requestCallback} requestId={ccRequestId} />
        </div>
    );
};

export default CallCenterLobby;