import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Form } from 'react-bootstrap';
import { CallCenter } from "../onsight-plus-ext";
import { getCurrentPage } from "../index";

export const RequestForm = () => {
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [messageVisibility, setMessageVisibility] = useState("hidden");
    const [comments, setComments] = useState("");
    const location = useLocation();

    const handleSubmit = async () => {
        setButtonDisabled(true);
        setMessageVisibility("unset");

        const newWindow = window.open("about:blank", "_blank");
        const response = await CallCenter.makeRequest("my-call-center", {
            requesterCompany: getCurrentPage().name,
            requesterUser: getCurrentPage().name,
            subject: "",
            requesterComments: comments
        });
        if (response.status === 201 && response.data.id) {
            let pathname = location.pathname;
            if (!pathname.endsWith('/')) {
                pathname += '/';
            }
            newWindow.location.href = `${pathname}lobby?ccReqId=${response.data.id}`;
            setComments("");
            setButtonDisabled(false);
            setMessageVisibility("hidden");
        }
    };

    return (
        <>
            <div className="card" style={{margin: "20px"}}>
                <div className="card-header font-weight-bold">Support Request Form</div>
                <div style={{ height: "100%", width: "100%", padding: "30px" }} className="">
                    <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
                        <div style={{ flexGrow: "1" }}>
                            <div style={{ height: "100%", width: "100%" }}>
                                {/* <Form.Control
                                    value={comments}
                                    onChange={evt => setComments(evt.target.value)}
                                    className="form-control me-2"
                                    type="text"
                                    placeholder="Comment..."
                                    aria-label="Comment"
                                />
                                <p /> */}
                                <Button onClick={handleSubmit} disabled={buttonDisabled} className="btn btn-primary ml-auto col-auto" type="submit">
                                    Request Support
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br />
            <p />
            <span style={{ visibility: messageVisibility }}>Submitting your request for support...</span>
        </>
    );
};