import React from 'react';
import ReactDOM from 'react-dom/client';
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { BrowserRouter as Router } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { b2cPolicies, loginRequest, msalConfig } from './authConfig';
import { CustomPageLowAuthService, MsalAuthService } from "./authService.js";
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/index.css';

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
//export const msalInstance = new PublicClientApplication(msalConfig);
//export const authService = new MsalAuthService(msalInstance, {
//    signInAuthority: b2cPolicies.authorities.signIn.authority,
//    signInScopes: loginRequest.scopes
//});
export const authService = new CustomPageLowAuthService();

let currentPage = {};
export const setCurrentPage = (page) => (currentPage = page);
export const getCurrentPage = () => currentPage;

// // Default to using the first account if no account is active on page load
// if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
//     // Account selection logic is app dependent. Adjust as needed for different use cases.
//     msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
// }

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Router>
        {/* <MsalProvider instance={msalInstance}> */}
            {/* <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}> */}
                <App authService={authService} />
            {/* </MsalAuthenticationTemplate> */}
        {/* </MsalProvider> */}
    </Router>
);
